// TitlePart.jsx
import React from 'react';

const TitlePart = ({ data }) => {
  return (
    <header className="relative text-center mb-10 px-2">
      {/* Gray Background */}
      <div className="absolute inset-x-0 top-1/3 bg-gray-100 h-20 z-0"></div>

      {/* Name Box */}
      <h1 className="relative inline-block border-[0.5px] md:border border-orange-500 bg-white z-10">
        {data.title}
      </h1>

      {/* Subtitle */}
      {data.elements.map((element, index) => (
        element.type === 'subtitle' && (
          <p key={index} className="relative text-2xl z-10">
            {element.content}
          </p>
        )
      ))}
    </header>
  );
};

export default TitlePart;
