import React from 'react';
import TitlePart from './TitlePart';
import LeftPart from './LeftPart';
import RightPart from './RightPart';
import ScrollToTopButton from './ScrollToTopButton';
import resumeData from '../store/resumeData.json';

const Resume = () => {

  const titleData = resumeData.sections.find(section => section.location === "title");
  const leftData = resumeData.sections.filter(section => section.location === "left");
  const rightData = resumeData.sections.filter(section => section.location === "right");

  return (

      <div className="max-w-5xl mx-auto py-8 bg-white shadow-lg">

        {/* Header Section */}
        <TitlePart data={titleData} />

        <div className="flex flex-col md:flex-row px-8">
          {/* Left Section: Contact and Skills */}
          <LeftPart sections={leftData} />

          {/* Horizontal Divider */}
          <div className="block md:hidden border-t-[0.5px] border-orange-400 w-full h-px bg-white mt-4 mb-7 mr-4 -ml-8"></div>
          
          {/* Vertical Line Divider */}
          <div className="hidden md:block border-r border-orange-400 w-0 bg-white mx-8"></div>

          {/* Right Section: Work Experience */}
          <RightPart sections={rightData} />
        </div>

        {/* Scroll-to-top button */}
        <ScrollToTopButton className="md:hidden" />

      </div>
  );

};

export default Resume;
