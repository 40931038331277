import React from 'react';
import iconMap from '../utils/iconMap';

const LeftPart = ({sections}) => {
    return (
        <div className="md:w-1/3 md:text-right">

            {sections.map((section, i) => (
                <div key={i}>
                    <section className="mb-6 md:mb-8">
                        <h2>{section.title}</h2>

                        {(() => {
                            switch (section.type) {
                                case 'skills':
                                    return section.elements.map((element, j) => (
                                        <div key={j} className="">
                                            <h3 className="mb-4 mt-6">{element.title}</h3>
                                            {element.items && <p>{element.items.join(', ')}</p>}
                                            {element.subcategories && element.subcategories.map((subcategory, k) => (
                                                <div key={k} className="mb-2">
                                                    {subcategory.title && <h4>{subcategory.title}</h4>}
                                                    <p>{subcategory.items.join(', ')}</p>
                                                </div>
                                            ))}
                                        </div>
                                    ));

                                case 'contact':
                                    return (
                                        <div className="flex flex-col gap-2">
                                            {section.elements.map((element, j) => (
                                                <div key={j} className="flex md:justify-end gap-3">
                                                    <div className="md:order-last">
                                                        {element.icon && iconMap[element.icon] && React.createElement(iconMap[element.icon], {className: "size-5 mt-0.5"})}
                                                        {element.icon && !iconMap[element.icon] && React.createElement(iconMap['unknown'], {className: "size-5 mt-0.5"})}
                                                    </div>
                                                    {element.url && <a className="text-yellow-500 hover:underline"
                                                                       href={element.url}>{element.value}</a>}
                                                    {!element.url && <div>{element.value}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    );

                                default:
                                    return null;
                            }
                        })()}

                        {/* Add more conditions for other section types as needed */}
                    </section>
                    {i < sections.length - 1 && (
                        <div className="block md:hidden border-t-[0.5px] border-orange-400 w-full h-px bg-white mt-4 mb-7 mr-4 -ml-8"></div>
                    )}
                </div>
            ))}

        </div>
    );
};

export default LeftPart;
