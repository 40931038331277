// Import desired icons from @heroicons/react
import {
  AtSymbolIcon,
  DevicePhoneMobileIcon,
  MapPinIcon,
  LinkIcon,
  QuestionMarkCircleIcon
  // Add more icons as needed
} from '@heroicons/react/20/solid';

// Create the mapping object
const iconMap = {
  email: AtSymbolIcon,
  phone: DevicePhoneMobileIcon,
  location: MapPinIcon,
  link: LinkIcon, 
  unknown: QuestionMarkCircleIcon,
  // Add more mappings as needed
};

// Optionally, export a default icon for cases where the specified icon doesn't exist
// import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
// const defaultIcon = QuestionMarkCircleIcon;

// Export both the iconMap and defaultIcon
export default iconMap;
